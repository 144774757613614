import { isPlatformServer } from '@angular/common';
import { Injectable, InjectionToken, OnDestroy, PLATFORM_ID, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, ReplaySubject } from 'rxjs';

export class ConsentCategory {
  public constructor(private _id: string) {}
  public get id() {
    return this._id;
  }
}

export interface UserConsentManager {
  request(categoryId: string): Promise<boolean>; // Open a dialog managed by the consent manager
  consent(categoryId: string): void; // Directly consent with a custom dialog
  acceptedCategories$: Observable<string[]>;
}

export const userConsentManagerToken = new InjectionToken<UserConsentManager>('user-consent:manager');

export interface UserConsent {
  /**
   * false = declined
   * true = accepted
   */
  readonly given$: Observable<boolean>;

  request(): void;
  consent(): void;
}

class UserConsentImpl implements UserConsent {

  private givenSubject$ = new ReplaySubject<boolean>(1);
  public readonly given$ = this.givenSubject$.asObservable();

  public constructor(
      private categoryId: string,
      private userConsentManager: UserConsentManager,
      platformServer: boolean,
  ) {
    this.userConsentManager.acceptedCategories$.pipe(
      takeUntilDestroyed(),
    ).subscribe((acceptedCategories) => {
      this.givenSubject$.next(acceptedCategories.includes(this.categoryId));
    })

    if (platformServer) {
      this.givenSubject$.next(false);
    }
  }

  public async request(): Promise<boolean> {
    return this.userConsentManager.request(this.categoryId);
  }

  /**
   * @deprecated should use request instead and let the consentManager show a dialog
   */
  public consent(): void {
    this.userConsentManager.consent(this.categoryId);
  }

  public onDestroy(): void {
    this.givenSubject$.complete();
  }

}

@Injectable({
  providedIn: 'root',
})
export class UserConsentService implements OnDestroy {

  private userConsentManager = inject(userConsentManagerToken);

  private instances = new Map<string, UserConsentImpl>();

  private platformServer = isPlatformServer(inject(PLATFORM_ID));

  public forCategory(category: ConsentCategory): UserConsent {
    if (!this.instances.has(category.id)) {
      this.instances.set(category.id, new UserConsentImpl(category.id, this.userConsentManager, this.platformServer));
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.instances.get(category.id)!;
  }

  public ngOnDestroy(): void {
    this.instances.forEach((consentInstance) => consentInstance.onDestroy());
  }

}
