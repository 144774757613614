import { Injectable, OnDestroy, InjectionToken, PLATFORM_ID, inject, DestroyRef } from '@angular/core';
import { combineLatest, fromEvent, ReplaySubject, Subject, firstValueFrom, EMPTY, startWith } from 'rxjs';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { SettingsService } from './settings.service';

import { debounceTime, filter, take } from 'rxjs/operators';
import { ConsentCategory, UserConsentManager } from './user-consent.service';
import { PageService } from './page.service';
import { NavigationEnd, Router } from '@angular/router';
import { HasEventTargetAddRemove } from 'rxjs/internal/observable/fromEvent';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type CategoryId = string;

type OneTrustConsentChangedEvent = CustomEvent<CategoryId[]>;

// https://developer.onetrust.com/onetrust/docs/javascript-api
declare const OneTrust: {
  /**
   * When true, OptanonAlertBoxClosed cookie is set. This will result in the Banner not being shown to the user, unless re-consent is flagged on initialization.
   */

  IsAlertBoxClosed: () => boolean;
  /**
   * Displays the Preference Center.
   */
  ToggleInfoDisplay: () => void;

  /**
   * Callback for consent changed event.
   * the detail in the event is a array of all active category ids
   */
   //OnConsentChanged: (callback: (event: OneTrustConsentChangedEvent) => void) => void;

   /**
    * Enable or disable consent for a category, host, or general vendor by providing the respective bit value
    * bitValue examples:
    * Enables consent for cookie category 3 "C0003:1"
    * Disable consent for cookie category 3 "C0003:0"
    * Enable consent for Host H2 "H2:1"
    * Disable consent for Host H2 "H2:0"
    * Enable consent for General Vendor V33 "V33:1"
    * Disable consent for General Vendor V33 "V33:0"
    */
    UpdateConsent: (groupType: 'Category'|'Host'|'General Vendor', bitValue: string) => void;

    /**
     * Update the view with the configured script details and verify implementation using a new Javascript method in the browser console.
     * @param {string} lang - 2-digit ISO 639 language code. Make sure to configure the language for the Template and published.
     */
    changeLanguage: (lang: string) => void;

    /**
     * Not in the official documentation
     * Initializes cookie list & Settings button
     */
    initializeCookiePolicyHtml: () => void;
}

export interface OneTrustServiceConfigurationInterface {
  production: boolean;
}

export const oneTrustServiceConfiguration = new InjectionToken<OneTrustServiceConfigurationInterface>('oneTrustServiceConfiguration');

export const NecessaryConsentCategory = new ConsentCategory('C0001');
export const PerformanceConsentCategory = new ConsentCategory('C0002');
export const FunctionalConsentCategory = new ConsentCategory('C0003');
export const MarketingConsentCategory = new ConsentCategory('C0004');

declare const window: {
  OptanonWrapper: () => void;
} & HasEventTargetAddRemove<OneTrustConsentChangedEvent>;

@Injectable({
  providedIn: 'root',
})
export class OneTrustService implements OnDestroy, UserConsentManager {

  private acceptedCategoriesSubject$ = new ReplaySubject<string[]>(1);
  public acceptedCategories$ = this.acceptedCategoriesSubject$.asObservable();

  private loaded$ = new ReplaySubject<void>(1);

  private readonly router = inject(Router);
  private readonly document = inject(DOCUMENT);
  private readonly configuration = inject(oneTrustServiceConfiguration);
  private readonly settingsService = inject(SettingsService);
  private readonly platformId = inject(PLATFORM_ID);
  private readonly pageService = inject(PageService);
  private destroyRef = inject(DestroyRef);

  public ngOnDestroy(): void {
    this.loaded$.complete();
  }

  public async request(): Promise<boolean> {
    // TODO: this should actually display the onetrust preference center, but only with the selected category displayed
    // This would ensure compliance with the law as the ui of the consent manager is not circumvented
    throw new Error('Method not implemented.');
  }

  public consent(categoryId: string): void {
    OneTrust.UpdateConsent('Category', `${categoryId}:1`);
  }

  public init() {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.settingsService.oneTrustDomainScript.pipe(
      take(1),
      filter(oneTrustDomainId => !!oneTrustDomainId),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((oneTrustDomainId) => {
      this.setupScriptLoadedHandler();
      this.loadScript(oneTrustDomainId);
    });

    this.setupCookieDetailsLinkHandler();
    this.setupConsentChangedHandler();
    this.setupRenderButtonAndListHandler();
    this.setupHideInitialDialogHandler();
  }

  private readonly renderButtonAndList$ = new Subject<void>();
  public renderButtonAndList(): void {
    this.renderButtonAndList$.next();
  }

  private setupCookieDetailsLinkHandler(): void {
    combineLatest([
      this.settingsService.cookieMoreLink,
      this.settingsService.privacyPolicyLink,
      this.loaded$,
    ]).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(([cookieDetailsHref, privacyPolicyHref]) => {
      this.document.getElementById('ot--cookie-notice-link')?.setAttribute('href', cookieDetailsHref);
      this.document.getElementById('ot--privacy-notice-link')?.setAttribute('href', privacyPolicyHref);
    });
  }

  private setupConsentChangedHandler(): void {
    // This event is undocumented!
    // This event is triggered on update and after load, but before OptanonWrapper
    // OnConsentChanged internally uses the same event
    fromEvent<OneTrustConsentChangedEvent>((window), 'OneTrustGroupsUpdated').pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((event) => {
      this.acceptedCategoriesSubject$.next(event.detail);
    });
  }

  private loadScript(oneTrustDomainId: string): void {
    const oneTrustScriptId = `${oneTrustDomainId}${this.configuration.production ? '' : '-test'}`;

    const scriptElement = this.document.createElement('script');
    scriptElement.src = `https://cdn.cookielaw.org/consent/${oneTrustScriptId}/otSDKStub.js`;
    scriptElement.async = true;
    scriptElement.setAttribute('type', 'text/javascript');
    scriptElement.setAttribute('charset', '"UTF-8');
    scriptElement.setAttribute('data-document-language', 'true'); // OneTrust implementation checks html tag language attribute
    scriptElement.setAttribute('data-domain-script', oneTrustScriptId);

    this.document.head.insertBefore(scriptElement, this.document.head.firstChild);
  }

  private setupScriptLoadedHandler(): void {
    let loadedOnce = false;
    window.OptanonWrapper = (): void => {
      // The OptanonWrapper is executed on load & on dialog close
      if (loadedOnce) {
        return;
      }
      loadedOnce = true;

      this.loaded$.next();
    }
  }

  private setupHideInitialDialogHandler(): void {
    combineLatest([
      this.loaded$,
      this.router.events.pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        startWith(EMPTY),
      ),
    ]).pipe(
      debounceTime(0),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(async () => {
      const page = await firstValueFrom(this.pageService.page);
      if (!OneTrust.IsAlertBoxClosed()) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const oneTrustBackgroundOverlayElement = this.document.querySelector<HTMLElement>('.onetrust-pc-dark-filter')!;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const oneTrustPreferenceCenterElement = this.document.querySelector<HTMLElement>('#onetrust-pc-sdk')!;

        if (page.disableInitialCookieDialog) {
          oneTrustBackgroundOverlayElement.style.display = 'none';
          oneTrustPreferenceCenterElement.style.display = 'none';
        } else {
          oneTrustBackgroundOverlayElement.style.removeProperty('display');
          oneTrustPreferenceCenterElement.style.removeProperty('display');
        }
      }
    });
  }

  private setupRenderButtonAndListHandler(): void {
    combineLatest([
      this.renderButtonAndList$,
      this.loaded$,
    ]).pipe(
      debounceTime(0),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(async () => {
      OneTrust.initializeCookiePolicyHtml();

      const oneTrustShowSettingsElements = this.document.querySelectorAll<HTMLButtonElement>('.ot-sdk-show-settings');
      oneTrustShowSettingsElements.forEach((oneTrustShowSettingsElement) => {
        oneTrustShowSettingsElement.removeEventListener('click', OneTrust.ToggleInfoDisplay);
        oneTrustShowSettingsElement.addEventListener('click', OneTrust.ToggleInfoDisplay);
      });

    });
  }
}
